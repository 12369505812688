import axios from "axios";

export const getContactCard = (username) => {
  axios
    .post("https://api.v1.entecard.mx/add_contact", { username })
    .then((r) => {
      var dataStr =
        "data:text/json;charset=utf-8," + encodeURIComponent(r.data);
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", `${username}.vcf`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
};

export const contabilizeView = (username) => {
  axios.post("https://api.v1.entecard.mx/new_view", { username });
};
