import theme from "styled-theming";

const th = (obj) => theme("template", obj);

const ModalTheme = {
  background: th({
    1: "#dad4cb",
    2: "rgb(81,90,93)",
    3: "linear-gradient(180deg,rgb(156,211,232) 0%,rgb(10,51,113) 100%)",
  }),
  color: th({
    1: "#b30e36",
    2: "white",
    3: "white",
  }),
  accept: {
    color: th({
      1: "#b30e36",
      2: "rgb(255,197,20)",
      3: "rgb(10,51,113)",
    }),
  },
  phone: {
    color: th({
      1: "#b30e36",
      2: "rgb(255,197,20)",
      3: "rgb(10,51,113)",
    }),
  },
};

export default ModalTheme;
