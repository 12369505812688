import * as React from "react";
import Social from "./social";
import Videos from "./video";
import Slider from "./slider";
import * as Styled from "./styled";
import { ReactHeight } from "react-height";

const EcardAddons = (props) => {
  return (
    <div style={{ ...props.style }}>
      <Styled.ECardAddonsContainer>
        {props.slideshows.length > 0 && (
          <Slider horizontal={props.horizontal}>
            {props.slideshows.map((s) => (
              <div data-src={s.image_url} />
            ))}
          </Slider>
        )}
        {props.videos.length > 0 &&
          props.videos.map((v) => <Videos url={v.external || v.local_url} />)}
        <ReactHeight
          onHeightReady={(height) => {
            props.getMarginTop(
              height,
              props.slideshows.length,
              props.videos.length
            );
          }}
        >
          <Social
            facebook={props.facebook}
            twitter={props.twitter}
            linkedin={props.linkedin}
            instagram={props.instagram}
            website={props.website}
          />
          {<Styled.Footer />}
        </ReactHeight>
      </Styled.ECardAddonsContainer>
    </div>
  );
};

export default EcardAddons;
