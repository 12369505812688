import Client from "../api/apolloclient";
import { gql } from "apollo-boost";

export const getTemplateSchemaByProfileId = (profileId, templateId) => {
  return Client.query({
    query: gql`
      {
        schematemplate(idprofile: ${profileId}, idtemplates: ${templateId}) {
          data { 
            property
            content
            section{
                property
            }
          }
        }
      }
    `,
  });
};
