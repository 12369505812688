import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 226 190" color="currentColor" {...props}>
      <g>
        <path
          d="M163.6,58.1c0-27.8-22.5-50.3-50.3-50.3c-27.8,0-50.3,22.5-50.3,50.3c0,10,2.9,19.3,7.9,27.1l-0.2-0.1
		c0,0,11.8,16.8,24.3,29.8c11.5,12,14.2,27.2,18.3,27.2c4.2,0,6.8-15.2,18.3-27.2c12.5-13,24.3-29.8,24.3-29.8l-0.2,0.1
		C160.7,77.4,163.6,68.1,163.6,58.1z M113.2,31.7c14.6,0,26.5,11.9,26.5,26.5c0,14.6-11.9,26.5-26.5,26.5
		c-14.6,0-26.5-11.9-26.5-26.5C86.8,43.5,98.6,31.7,113.2,31.7z"
        />
        <path
          d="M220,170.4c-0.9-2-22.5-47.9-26.5-55.3c-4.6-8.4-13-10.7-17.3-10.7c0,0,0,0,0,0h-21.2l-11.5,12.8h32.6
		c0.5,0,4.2,0.4,6.2,4c2.8,5.1,15.2,31.4,21.9,45.6h-91h-91c6.7-14.2,19.1-40.4,21.9-45.6c2-3.7,5.6-4,6.2-4h32.6l-11.5-12.8H50.2
		c0,0,0,0,0,0c-4.3,0-12.7,2.2-17.3,10.7c-4,7.4-25.6,53.4-26.5,55.3c-0.9,2-0.8,4.3,0.4,6.1c1.2,1.8,3.2,3,5.4,3h101h101
		c2.2,0,4.2-1.1,5.4-3C220.8,174.7,220.9,172.4,220,170.4z"
        />
      </g>
    </svg>
  );
};
