import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 237 180" color="currentColor" {...props}>
      <path
        d="M218.5,5.6H18.6C11.9,5.6,6.3,11,6.3,17.8v143.7c0,6.7,5.5,12.2,12.3,12.2h199.9c6.8,0,12.3-5.5,12.3-12.2V17.8
	C230.8,11,225.3,5.6,218.5,5.6z M130.4,91.9c-0.3,0.2-6,4.8-12.2,4.8c-6.3,0-12-4.6-12.2-4.8L22.5,18.1h191.5L130.4,91.9z
	 M93.5,97.6l-74.6,56.1V31.7L93.5,97.6z M103.9,105.4c3.6,1.9,8.6,3.8,14.3,3.8c5.7,0,10.7-1.9,14.3-3.8l74.1,55.7H29.8L103.9,105.4
	z M142.9,97.6L218.3,31v123.2L142.9,97.6z"
      />
    </svg>
  );
};
