import * as React from "react";
import ReactPlayer from "react-player";

const Videos = ({ url }) => {
  console.log(ReactPlayer.canPlay(url));
  return (
    <ReactPlayer
      url={url}
      controls={true}
      style={{ width: "80%", margin: "20px auto", backgroundColor: "black" }}
    />
    // <video src={url}></video>
  );
};
export default Videos;
