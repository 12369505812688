//@flow
import * as React from "react";
import ECard from "./ecard";
import * as Types from "./types";
import { getProfileById } from "../api/profiles";
import styled, { ThemeProvider } from "styled-components";
import { withRouter } from "react-router-dom";
import { BallBeat } from "react-pure-loaders";
import * as Styled from "./styled";
import EcardAddons from "./ecardAddons";
import { getTemplateSchemaByProfileId } from "../api/schemas";

type Props = {
  profileId: number,
};

type State = {
  userProfile: Types.Profile,
  templateId: number,
  demo: boolean,
  loading: boolean,
  width: Number,
};

class ECardView extends React.Component<Props, State> {
  state = {
    userProfile: {},
    templateId: 0,
    demo: false,
    loading: true,
    width: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    height: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
    heightFooter: 0,
  };

  updateDimensions = (e) => {
    const width = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const height = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    this.setState({
      width,
      height,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const { location } = this.props;
    let urlParams = new URLSearchParams(location.search);
    getProfileById(this.props.profileId).then((result) =>
      getTemplateSchemaByProfileId(
        result.data.profileuser.idprofile,
        urlParams.get("template") || result.data.profileuser.idtemplate
      ).then((schemas) =>
        this.setState({
          demo: urlParams.get("demo") === "true" || false,
          userProfile: result.data.profileuser,
          templateId:
            urlParams.get("template") || result.data.profileuser.idtemplate,
          loading: false,
          schemas: mapSchemas(schemas),
        })
      )
    );
  }
  // CustomsContext = React.createContext({});

  getMarginTop = (height, slide, video) => {
    let scale = Math.min(this.state.width / 778, 1);
    let content = 960 * scale;
    let footer = height * scale;
    if (content + footer < this.state.height && !slide && !video) {
      let heightFooter = this.state.height - (height * scale + scale * 960);
      this.setState({
        heightFooter,
      });
    }
  };

  render() {
    return this.state.templateId ? (
      // <this.CustomsContext.Provider value={this.state.userProfile.schema}>
      <ThemeProvider
        theme={{
          template: this.state.templateId,
          customization: this.state.schemas || {},
        }}
      >
        {this.state.demo && (
          <Corner
            onChange={(e) => {
              const { value } = e.target;
              getTemplateSchemaByProfileId(
                this.state.userProfile.idprofile,
                value
              ).then((schemas) =>
                this.setState({
                  templateId: value,
                  schemas: mapSchemas(schemas),
                })
              );
            }}
            value={this.state.templateId}
          >
            {[1, 2, 3].map((t) => (
              <option key={t} value={t}>{`template ${t}`}</option>
            ))}
          </Corner>
        )}
        <Styled.Backgrounder style={{ maxWidth: "778px", margin: "auto" }}>
          <div
            style={{
              maxHeight: `${960 * Math.min(this.state.width / 778, 1)}px`,
            }}
          >
            <ECard
              style={{
                position: "relative",
                resize: "both",
                transform: `scale(${Math.min(this.state.width / 778, 1)})`,
                transformOrigin:
                  this.state.width > 778 ? "center top" : "left top",
              }}
              {...this.state.userProfile}
            ></ECard>
          </div>
          <div
            style={{
              //   maxHeight: `${960 * Math.min(this.state.width / 778, 1)}px`,
              height: "0",
              marginTop: `${this.state.heightFooter}px`,
            }}
          >
            <EcardAddons
              id="EcardAddons"
              getMarginTop={this.getMarginTop}
              {...this.state.userProfile}
              style={{
                position: "relative",
                resize: "both",
                transform: `scale(${Math.min(this.state.width / 778, 1)})`,
                transformOrigin:
                  this.state.width > 778 ? "center top" : "left top",
              }}
            />
          </div>
        </Styled.Backgrounder>
      </ThemeProvider>
    ) : (
      // </this.CustomsContext.Provider>
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
          top: "50%",
        }}
      >
        <BallBeat color={"#123abc"} loading={this.state.loading} />
      </div>
    );
  }
}
export default withRouter(ECardView);

const mapSchemas = (schemas) => {
  return schemas?.data?.schematemplate?.data
    .map((d) => ({
      [d.section.property.concat("_", d.property).replace("-", "_")]: d.content,
    }))
    .reduce((r, c) => Object.assign(r, c));
};

const Corner = styled.select`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
