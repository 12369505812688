import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 41 40" fill="currentColor" {...props}>
        <path d="M20.2,1.4c-10.5,0-19,8.5-19,19c0,10.5,8.5,19,19,19c10.5,0,19-8.5,19-19C39.2,9.9,30.7,1.4,20.2,1.4z M36.5,19h-7.1 c-0.2-5.9-1.6-11-3.7-14.1C31.7,7.1,36,12.5,36.5,19z M20.2,36.7c-3,0-6.3-6.1-6.5-15.1h13.1C26.5,30.6,23.2,36.7,20.2,36.7z
	        M13.7,19C14,10.1,17.2,4,20.2,4c3,0,6.3,6.1,6.5,15.1H13.7z M14.7,4.9C12.6,8.1,11.2,13.1,11,19H3.9C4.4,12.5,8.8,7.1,14.7,4.9z
	        M3.9,21.7H11c0.2,5.9,1.6,11,3.7,14.1C8.8,33.7,4.4,28.2,3.9,21.7z M25.7,35.8c2.1-3.2,3.5-8.2,3.7-14.1h7.1
	        C36,28.2,31.7,33.7,25.7,35.8z" 
       />
    </svg>
  );
};