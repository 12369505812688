import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 155 196" color="currentColor" {...props}>
      <path
        d="M119.9,134.4c-6.6,0-12.7,2.3-17.5,6.2l-40.8-31.5c1.6-3.5,2.5-7.4,2.5-11.5c0-4.1-0.9-8-2.5-11.5l40.8-31.5
	c4.8,3.9,10.8,6.2,17.5,6.2c15.3,0,27.8-12.4,27.8-27.8c0-15.3-12.4-27.8-27.8-27.8c-15.3,0-27.8,12.4-27.8,27.8
	c0,4.1,0.9,8,2.5,11.5L53.9,76.1c-4.8-3.9-10.8-6.2-17.5-6.2c-15.3,0-27.7,12.4-27.7,27.8c0,15.3,12.4,27.8,27.7,27.8
	c6.6,0,12.7-2.3,17.5-6.2l40.8,31.5c-1.6,3.5-2.5,7.4-2.5,11.5c0,15.3,12.4,27.8,27.8,27.8c15.3,0,27.8-12.4,27.8-27.8
	C147.7,146.8,135.3,134.4,119.9,134.4z"
      />
    </svg>
  );
};
