import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 177 238" color="currentColor" {...props}>
      <g>
        <path
          d="M126.4,136.7c0,0-4.4-0.6-4.4-4.1c0,0-1.3-13,1.6-17.4c2.8-4.4,8.9-22.5,10.5-26c1.6-3.5,6-17.7-1-20
		c0,0-0.3-32.3-0.3-34.5s-0.6-8.2-12-15.8c-5.1-4.4-11.7-9.8-5.1-15.2c0,0-51.9-1.3-58,29.8c0,0-1.9,5.7-1,34.8c0,0-5.1,1-3.2,10.1
		c1.9,8.9,9.2,31.3,10.1,33.6c0.9,2.2,3.5,3.2,3.8,5.7c0.3,2.5,1.3,16.5-5.7,17.7l-7,16.5L3.5,175.2c21.2,35.6,60,59.4,104.5,59.4
		c3,0,5.9-0.1,8.8-0.3c-5.1-7.5-8.1-16.6-8.1-26.4c0-26.1,21.1-47.2,47.2-47.2l-26-11.7L126.4,136.7z"
        />
        <polygon
          points="170.9,192 152.6,192 152.6,173.7 139,173.7 139,192 120.7,192 120.7,205.6 139,205.6 139,223.9 
		152.6,223.9 152.6,205.6 170.9,205.6 	"
        />
      </g>
    </svg>
  );
};
