//@flow
import * as React from "react";
import { Theme as t } from "./themes";
import styled from "styled-components";
import * as Icons from "../icons";

type Props = {
  facebook: string,
  instagram: string,
  twitter: string,
  linkedin: string,
  website: string,
};

const Social = ({ facebook, instagram, twitter, linkedin, website }: Props) => {
  return (
    <SocialContainer>
      {facebook && (
        <SocialLogo onClick={() => window.open(`${facebook}`, "_blank")}>
          <Icons.Facebook width="35px" height="35px" />
        </SocialLogo>
      )}
      {twitter && (
        <SocialLogo onClick={() => window.open(`${twitter}`, "_blank")}>
          <Icons.Twitter width="35px" height="35px" />
        </SocialLogo>
      )}
      {instagram && (
        <SocialLogo onClick={() => window.open(`${instagram}`, "_blank")}>
          <Icons.Insta width="35px" height="35px" />
        </SocialLogo>
      )}
      {linkedin && (
        <SocialLogo onClick={() => window.open(`${linkedin}`, "_blank")}>
          <Icons.LinkedIn width="35px" height="35px" />
        </SocialLogo>
      )}
      {website && (
        <SocialLogo onClick={() => window.open(`${website}`, "_blank")}>
          <Icons.Website width="35px" height="35px" />
        </SocialLogo>
      )}
    </SocialContainer>
  );
};

export default Social;

const SocialLogo = styled.div`
  width: 50px;
  height: 40px;
  margin: 10px 10px;
  padding-top: 5px;
  text-align: center;
  background-color: ${(a) =>
    a.theme.customization.networks_background_color ||
    t.SocialLogo.backgroundColor};
  border-radius: 50%;
  color: ${(a) => a.theme.customization.networks_color || t.SocialLogo.color};
  cursor: pointer;
`;

const SocialContainer = styled.div`
  display: flex;
  min-height: 36px;
  justify-content: flex-end;
  text-align: right;
  padding: 20px;
  padding: 20px;
  position: ${t.SocialContainer.position};
  top: ${t.SocialContainer.top};
  width: ${t.SocialContainer.width};
`;
