import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 282 137" color="currentColor" {...props}>
      <g>
        <path
          d="M269.6,64.7c-0.5-0.6-12.7-14.5-34.7-28.4c-20.2-12.9-52.9-28.2-93.8-28.2c-40.8,0-73.5,15.4-93.8,28.2
		c-21.9,14-34.2,27.8-34.7,28.4l-3.5,4l3.5,4c0.5,0.6,12.7,14.5,34.7,28.4c18.9,12,48.6,26.2,85.7,28c2.7,0.4,5.4,0.5,8.1,0.5
		c2.7,0,5.4-0.2,8.1-0.5c37-1.8,66.8-16,85.7-28c21.9-14,34.2-27.8,34.7-28.4l3.5-4L269.6,64.7z M189.7,68.9
		c0,24.5-18.2,44.8-41.7,48.1c-2.3,0.1-4.6,0.2-6.9,0.2c-2.3,0-4.6-0.1-6.9-0.2c-23.5-3.4-41.7-23.7-41.7-48.1
		c0-26.8,21.8-48.6,48.6-48.6S189.7,42.1,189.7,68.9z M25.8,68.8c5-4.9,14.6-13.5,28.1-22.1c10.9-6.9,25.6-14.6,43.3-19.9
		c-10.5,10.9-17,25.8-17,42.1c0,16.1,6.3,30.8,16.6,41.7C79.4,105.4,65,97.9,54.3,91.1C40.6,82.5,30.8,73.7,25.8,68.8z M228.3,90.9
		c-10.8,6.9-25.3,14.5-42.9,19.7C195.7,99.7,202,85.1,202,68.9c0-16.3-6.5-31.2-17-42.1c17.6,5.2,32.2,12.8,43,19.6
		c13.7,8.7,23.4,17.4,28.5,22.3C251.5,73.7,241.9,82.3,228.3,90.9z"
        />
        <path
          d="M141.1,96.1c15,0,27.2-12.2,27.2-27.2s-12.2-27.2-27.2-27.2c-4.7,0-9.2,1.2-13.1,3.4l13.1,23.8l-22.7-15
		c-2.8,4.3-4.5,9.4-4.5,15C113.9,83.9,126.1,96.1,141.1,96.1z"
        />
      </g>
    </svg>
  );
};
