import styled from "styled-components";
import { Theme as t } from "./themes";
import * as Icons from "../icons";
// console.log(Customs);

export const CardContainer = styled.div`
  display: flex;
  flex-direction: ${t.CardContainer.flexDirection};
  width: 778px;
  margin: auto;
  position: relative;
  background: ${(a) =>
    a.theme.customization.card_container_background ||
    t.CardContainer.background};
  background-size: ${t.CardContainer.backgroundSize};
`;
export const Profile = styled.div`
  height: ${t.Profile.height};
  align-items: ${t.Profile.alignItems};
  position: relative;
  width: ${t.Profile.width};
  display: flex;
  flex-direction: ${t.Profile.flexDirection};
  // flex-direction: column;
  justify-items: space evenly;
`;

export const ActionContainer = styled.div`
  height: ${t.ActionContainer.height};
  /* background: ${t.ActionContainer.background}; */
  background: ${(a) =>
    a.theme.customization.action_container_background ||
    t.ActionContainer.background};
  position: ${t.ActionContainer.position};
  width: ${t.ActionContainer.width};
  margin-left: ${t.ActionContainer.marginLeft};
  padding-top: ${t.ActionContainer.paddingTop};
  padding-bottom: ${t.ActionContainer.paddingBottom};
  box-shadow: ${t.ActionContainer.boxShadow};
  align-items: ${t.ActionContainer.alignItems};
  right: ${t.ActionContainer.right};
  z-index: ${t.ActionContainer.zIndex};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${t.ActionContainer.justifyContent};
  cursor: pointer;
`;

export const PictureContainer = styled.div`
  width: ${t.PictureContainer.width};
  position: relative;
  height: ${t.PictureContainer.height};
  display: flex;
  margin-top: ${t.PictureContainer.marginTop};
  margin-left: ${t.PictureContainer.marginLeft};
  margin-right: ${t.PictureContainer.marginRight};
  background-color: ${(a) =>
    a.theme.customization.picture_container_background_color ||
    t.PictureContainer.backgroundColor};
`;

export const Picture = styled.img`
  height: ${t.Picture.height};
  width: ${t.Picture.width};
  // border-radius: 50%;
  border-radius: ${t.Picture.borderRadius};
  margin: ${t.Picture.margin};
  margin-right: ${t.Picture.marginRight};
  margin-left: ${t.Picture.marginLeft};
  box-shadow: ${t.Picture.boxShadow};
`;

export const LogoContainer = styled.div`
  display: ${t.LogoContainer.display};
  top: ${t.LogoContainer.top};
  position: ${t.LogoContainer.position};
  left: ${t.LogoContainer.left};
  z-index: ${t.LogoContainer.zIndex};
  height: ${t.LogoContainer.height};
  order: ${t.LogoContainer.order};
  margin: ${t.LogoContainer.margin};
`;

export const Logo = styled.img`
  height: ${t.Logo.height};
  width: ${t.Logo.width};
`;

export const TextContainer = styled.div`
  flex-direction: ${t.TextContainer.flexDirection};
  width: ${t.TextContainer.width};
  display: flex;
  height: ${t.TextContainer.height};
  background: ${t.TextContainer.background};
  // margin: 12px 0 0 0;
  margin: ${t.TextContainer.margin};
  justify-content: ${t.TextContainer.justifyContent};
`;

export const TextContainerDecoration = styled.div`
  display: ${t.TextDecorationContainer.display};
  margin-right: ${t.TextDecorationContainer.marginRight};
  margin-left: ${t.TextDecorationContainer.marginLeft};
  margin-top: ${t.TextDecorationContainer.marginTop};
  height: ${t.TextDecorationContainer.height};
  min-height: ${t.TextDecorationContainer.minHeight};
  width: ${t.TextDecorationContainer.width};
  background: ${t.TextDecorationContainer.background};
  border: ${(a) =>
    a.theme.customization.separator_border || t.TextDecorationContainer.border};
  order: ${t.TextDecorationContainer.order};
`;

export const Company = styled.div`
  display: ${t.Company.display};
  order: ${t.Company.order};
  margin: ${t.Company.margin};
  color: ${(a) => a.theme.customization.company_label_color || t.Company.color};
  font-family: ${t.Company.fontFamily};
  letter-spacing: ${t.Company.letterSpacing};
  width: ${t.Company.width};
  text-align: center;
  font-size: 32px;
`;

export const Name = styled.div`
  color: ${(a) => a.theme.customization.name_label_color || t.Name.color};
  width: ${t.Name.width};
  margin-left: ${t.Name.marginLeft};
  text-align: ${t.Name.textAlign};
  position: ${t.Name.position};
  font-size: 32px;
  font-family: "Helvetica", sans-serif;
  font-weight: bold;
  height: auto;
  letter-spacing: -3px;
`;
export const Job = styled.div`
  color: ${(a) => a.theme.customization.job_label_color || t.Job.color};
  text-align: ${t.Job.textAlign};
  position: ${t.Job.position};
  width: ${t.Job.width};
  margin-left: ${t.Job.marginLeft};
  margin-top: ${t.Job.marginTop};
  order: ${t.Job.order};
  font-family: "PT Sans", sans-serif;
  font-size: 32px;
  height: 40px;
  letter-spacing: -3px;
`;

export const ActionButton = styled.div`
  color: ${t.ActionButton.color};
  width: ${t.ActionButton.width};
  height: ${t.ActionButton.height};
  margin-bottom: ${t.ActionButton.marginBottom};
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: end; */
  justify-content: flex-start;
  font-family: "Helvetica", sans-serif;
  & img {
    width: 39%;
  }
  & svg {
    width: 79%;
    height: 79%;
    margin: 10%;
  }
`;
export const Footer = styled.div`
  width: 100%;
  // position: fixed;
  // bottom: 0;
  display: ${({ hide }) => (hide ? "none" : "block")};
  height: ${t.Footer.height};
  background: ${(a) =>
    a.theme.customization.footer_background_color || t.Footer.background};
  position: ${t.Footer.position};
  bottom: ${t.Footer.bottom};
`;
export const VerticalFooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 2.7%;
  background: ${t.Footer.background};
`;

export const ViewCounter = styled.span`
  margin: auto;
`;

export const Icon = styled.div`
  background-color: ${(a) =>
    a.theme.customization.buttons_background_color || t.Icon.backgroundColor};
  color: ${(a) => a.theme.customization.buttons_color || t.Icon.color};
  border-radius: 50%;
  width: ${t.Icon.width};
  height: ${t.Icon.height};
`;

export const LocationIcon = styled(Icons.LocationIcon)`
  position: ${t.LocationIcon.position};
  bottom: ${t.LocationIcon.bottom};
  left: ${t.LocationIcon.left};
  // color: ${t.LocationIcon.color};
  color: ${(a) =>
    a.theme.customization.buttons_extras_color || t.LocationIcon.color};
  margin: 0;
`;

export const ShareIcon = styled(Icons.ShareIcon)`
  color: ${(a) =>
    a.theme.customization.buttons_extras_color || t.ShareIcon.color};
  margin: 0;
`;

export const ViewsIcon = styled(Icons.ViewsIcon)`
  color: ${(a) =>
    a.theme.customization.buttons_extras_color || t.ViewsIcon.color};
`;

export const Location = styled(ActionButton)`
  height: ${t.Location.height};
  & svg {
    width: ${t.Location.svgWidth};
    height: 100%;
    margin: 0;
  }
`;
export const Share = styled(ActionButton)`
  height: ${t.Share.height};
  & svg {
    width: ${t.Share.svgWidth};
    height: 100%;
    margin: 0;
  }
`;

export const Views = styled(ActionButton)`
  height: ${t.Views.height};
  & svg {
    width: ${t.Views.svgWidth};
    height: 100%;
    margin: 0;
  }
`;
export const Whatsapp = styled(ActionButton)`
  height: ${t.Whatsapp.height};
`;

export const QRCode = styled(ActionButton)`
  position: ${t.QRCode.position};
  bottom: ${t.QRCode.bottom};
  right: ${t.QRCode.right};
  /* color: ${t.QRCode.color}; */
  color: ${(a) => a.theme.customization.qr_code_color || t.QRCode.color};

  width: ${t.QRCode.width};
  & img {
    width: ${t.QRCode.imgWidth};
  }
  & svg {
    fill: ${t.QRCode.color}!important;
  }
`;
export const Empty = styled(ActionButton)`
  display: ${t.EmptyAction.display};
`;

export const IconText = styled.span`
  margin-top: 8px;
  width: ${t.IconText.width};
  max-width: 100%;
  font-weight: 700;
  font-size: ${t.IconText.fontSize};
  color: ${(a) => a.theme.customization.buttons_title_color || "currentColor"};
  text-align: center;
  & svg {
    width: 100%;
    height: auto;
    margin: 0;
    fill: ${(a) =>
      a.theme.customization.buttons_title_color || t.IconText.color};
  }
`;

export const Backgrounder = styled.div`
  height: 100%;
  width: 100%;
  max-width: 778px;
  background: ${(a) =>
    a.theme.customization.general_background_background ||
    t.Backgrounder.background};
  position: relative;
`;

export const Slides = styled.div`
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 500px;
  overflow: hidden;
  margin: auto;
`;

export const ECardAddonsContainer = styled.div`
  width: 778px;
  margin: auto;
  top: ${t.ECardAddonsContainer.top};
  position: ${t.ECardAddonsContainer.position};
  background: ${(a) =>
    a.theme.customization.extra_info_container_background ||
    t.ECardAddonsContainer.backgroundColor};
  background-size: ${t.ECardAddonsContainer.backgroundSize};
`;
