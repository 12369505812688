import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import ECardView from "./ecard/ecardview";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:profileId">
          <ShowProfileCard />
        </Route>
      </Switch>
    </Router>
  );
}

function ShowProfileCard() {
  let { profileId } = useParams();
  return <ECardView profileId={profileId}></ECardView>;
}

export default App;
