import theme from "styled-theming";
import ModalTheme from "./theme/modal";

const th = (obj) => theme("template", obj);

export const Theme = {
  Modal: ModalTheme,
  Profile: {
    height: th({
      1: "464px",
      2: "556px",
      3: "952px",
      4: "560px",
    }),
    alignItems: th({
      1: "flex-end",
      2: "flex-end",
      3: "flex-start",
    }),
    width: th({
      1: "100%",
      2: "100%",
      3: "69.5%",
      4: "100%",
    }),
    flexDirection: th({
      1: "column",
      2: "column",
      3: "column",
      4: "row",
    }),
  },
  Backgrounder: {
    background: th({
      1: "#dad4cb",
      2: "rgb(81,90,93)",
      3: "white",
      4: "linear-gradient(90deg,rgb(247,247,247) 0px,rgb(247,247,247) 284px,rgb(246,155,12) 285px,rgb(246,155,12) 100%)",
    }),
  },

  CardContainer: {
    background: th({
      1: "#dad4cb",
      2: "rgb(81,90,93) url(images/background-2.png) no-repeat",
      3: "url(images/template3-profile-background.png) no-repeat",
      4: "linear-gradient(90deg,rgb(247,247,247) 0px,rgb(247,247,247) 284px,rgb(246,155,12) 285px,rgb(246,155,12) 100%)",
    }),

    backgroundSize: th({
      1: "none",
      2: "100% 22%",
      3: "100% 47%",
    }),
    flexDirection: th({
      1: "column",
      2: "column",
      3: "row",
      4: "column",
    }),
  },
  ECardAddonsContainer: {
    backgroundColor: th({
      1: "#dad4cb",
      2: "rgb(81,90,93)",
      4: "linear-gradient(90deg,rgb(247,247,247) 0px,rgb(247,247,247) 284px,rgb(246,155,12) 285px,rgb(246,155,12) 100%)",
    }),
    backgroundSize: th({
      1: "none",
      2: "100% 22%",
      3: "100% 47%",
    }),
    position: th({
      1: "relative",
      2: "relative",
      3: "absolute",
    }),
    top: th({
      3: "140px",
    }),
  },

  PictureContainer: {
    width: th({
      1: "69.4%",
      2: "100%",
      3: "100%",
      4: "284px",
    }),
    height: th({
      1: "59%",
      2: "49.6%",
      3: "80%",
      4: "252px",
    }),
    marginTop: th({
      1: "11%",
      2: "17.4%",
      3: "%",
      4: "108px",
    }),
    marginLeft: th({
      1: "0",
      2: "0",
      3: "0%",
    }),
    marginRight: th({
      1: "auto",
      2: "",
    }),
    backgroundColor: th({
      1: "#b30e36",
      2: "none",
      4: "black",
    }),
  },

  Picture: {
    marginRight: th({
      1: "96px",
      2: "auto",
      // 3: "0",
    }),
    margin: th({
      3: "auto",
    }),
    height: th({
      1: "100%",
      2: "100%",
      // 3: "72%",
    }),
    marginLeft: th({
      1: "96px",
      2: "auto",
      // 3: "30%",
    }),
    width: th({
      4: "284px",
    }),
    borderRadius: th({
      1: "50%",
      2: "50%",
      3: "50%",
      4: "0px",
    }),
    boxShadow: th({
      2: "3px 15px 23px -5px",
      3: "3px 15px 23px -5px",
    }),
  },

  LogoContainer: {
    display: th({
      2: "none",
    }),
    top: th({
      3: "620px",
      4: "300px",
    }),
    position: th({
      3: "absolute",
      4: "absolute",
    }),
    left: th({
      3: "182px",
      4: "70px",
    }),
    zIndex: th({
      1: "0",
      3: "1",
    }),
    height: th({
      1: "199px",
      3: "90px",
    }),
    order: th({
      1: "-1",
    }),
    margin: th({
      1: "36px 54px 36px 54px",
    }),
  },
  Logo: {
    height: th({
      1: "100%",
      4: "150px",
    }),
    width: th({
      3: "180px",
    }),
    // marginLeft: th({
    //   1: "10vh",
    // }),
  },

  Company: {
    display: th({
      1: "block",
      2: "block",
      3: "block",
    }),
    order: th({
      2: "2",
      3: "2",
      4: "1",
    }),
    margin: th({
      1: "auto",
      2: "0 auto",
      3: "0 auto",
    }),
    color: th({
      1: "black",
      2: "rgb(255,197,20)",
      4: "white",
    }),
    fontFamily: th({
      1: '"PT Sans", sans-serif',
      2: '"Helvetica", sans-serif',
      3: '"Helvetica", sans-serif',
    }),
    letterSpacing: th({
      1: "-1px",
    }),
    width: th({
      1: "335px",
      2: "100%",
      3: "100%",
    }),
  },

  Name: {
    color: th({
      1: "black",
      2: "rgb(255,197,20)",
      4: "rgb(246,155,12)",
    }),
    width: th({
      1: "49%",
      2: "100%",
      3: "100%",
      4: "496px",
    }),
    marginLeft: th({
      1: "391px",
      2: "0",
    }),
    textAlign: th({
      1: "justify",
      2: "center",
      3: "center",
      4: "center",
    }),
    position: th({
      1: "absolute",
      2: "inherit",
    }),
    order: th({
      2: "1",
      3: "1",
    }),
  },

  Job: {
    color: th({
      1: "#847256",
      2: "white",
      3: "rgb(172,174,173)",
      4: "white",
    }),
    textAlign: th({
      1: "justify",
      2: "center",
      3: "center",
      4: "center",
    }),
    position: th({
      1: "absolute",
      2: "relative",
    }),
    width: th({
      1: "50%",
      2: "100%",
    }),
    marginLeft: th({
      1: "391px",
      2: "0",
    }),
    marginTop: th({
      1: "40px",
      2: "0",
    }),
    order: th({
      2: "3",
      3: "3",
      4: "2",
    }),
  },

  TextDecorationContainer: {
    marginRight: th({
      1: "439px",
      2: "0",
    }),
    display: th({
      3: "none",
    }),
    marginLeft: th({
      3: "16.3%",
    }),
    marginTop: th({
      2: "1%",
      3: "9.8%",
    }),
    height: th({
      1: "80px",
      2: "8px",
      3: "30%",
    }),
    minHeight: th({
      // 1: "80px",
      2: "8px",
      // 3: "30%",
    }),
    width: th({
      1: "0",
      2: "100%",
      3: "65.2%",
    }),
    background: th({
      1: "none",
      2: "linear-gradient(90deg, rgb(255,197,20) 0%,white 50%, rgba(255,255,255,0.4) 100%)",
      3: "url(images/template3-decoration.png) round",
    }),
    border: th({
      1: "solid 2px black",
      2: "none",
      3: "",
    }),
    order: th({
      2: "4",
      3: "4",
    }),
  },

  TextContainer: {
    flexDirection: th({
      1: "row",
      2: "column",
      3: "column",
      4: "column",
    }),
    background: th({
      4: "black",
    }),
    width: th({
      1: "778px",
      2: "100%",
      3: "100%",
      4: "495px",
    }),
    height: th({
      1: "80px",
      2: "16.2vh",
      3: "100%",
      4: "252px",
    }),
    margin: th({
      1: "12px 0 0 0",
      2: "12px 0 0 0",
      3: "12px 0 0 0",
      4: "108px 0 0 0",
    }),
    justifyContent: th({
      4: "flex-end",
    }),
  },

  ActionContainer: {
    height: th({
      1: "400px",
      2: "394px",
      3: "945px",
      4: "400px",
    }),
    background: th({
      3: "linear-gradient(180deg,rgb(156,211,232) 0%,rgb(10,51,113) 100%)",
    }),
    position: th({
      3: "relative",
    }),
    right: th({
      3: "0",
    }),
    width: th({
      1: "100%",
      2: "100%",
      3: "180px",
      4: "100%",
    }),
    marginLeft: th({
      // 3: "69.3%",
      // 4: "284px",
    }),
    paddingTop: th({
      1: "100px",
      2: "3%",
      3: "120px",
    }),
    paddingBottom: th({
      3: "35px",
    }),
    boxShadow: th({
      3: "3px 15px 23px -5px",
    }),
    alignItems: th({
      2: "end",
    }),
    zIndex: th({
      3: "1",
    }),

    justifyContent: th({
      1: "center",
      2: "center",
      3: "center",
      4: "flex-end",
    }),
  },
  ActionButton: {
    color: th({
      1: "#b30e36",
      2: "white",
      3: "white",
    }),
    width: th({
      1: "33%",
      2: "33%",
      3: "100%",
      4: "32%",
    }),
    height: th({
      2: "34%",
      3: "13%",
      4: "130px",
    }),
    fontSize: th({
      1: "0.78rem",
      2: "13.8px",
    }),
    marginBottom: th({
      3: "10px",
    }),
  },
  Icon: {
    backgroundColor: th({
      1: "black",
      2: "rgb(255,197,20)",
      3: "white",
      4: "black",
    }),
    color: th({
      1: "white",
      2: "black",
      3: "rgb(10,51,113)",
      4: "white",
    }),
    height: th({
      1: "89px",
      2: "58.5%",
      3: "67%",
      4: "91px",
    }),
    width: th({
      1: "89px",
      2: "31.5%",
      3: "45%",
      4: "91px",
    }),
  },
  Footer: {
    background: th({
      1: "#b30e36",
      2: "linear-gradient(90deg, rgb(255,197,20) 0%,white 50%, rgba(255,255,255,0.4) 100%)",
      3: "url(images/template3-footer.png)",
      4: "black",
    }),
    height: th({
      1: "36px",
      2: "34px",
      3: "49px",
      4: "36px",
    }),
    position: th({
      3: "absolute",
    }),
    bottom: th({
      3: "-50px",
    }),
  },
  IconText: {
    color: th({
      1: "#b30e36",
      2: "white",
      3: "white",
    }),
    width: th({
      1: "50%",
      2: "50%",
      3: "135px",
      4: "57%",
    }),
    fontSize: th({
      1: "15px",
      2: "15px",
      3: "15px",
    }),
  },

  LocationIcon: {
    color: th({
      1: "black",
      2: "rgb(255,197,20)",
      3: "white",
      4: "black",
    }),
    // position: th({
    //   4: "absolute",
    // }),
    // bottom: th({
    //   4: "100px",
    // }),
    // left: th({
    //   4: "100px",
    // }),
  },
  ShareIcon: {
    color: th({
      1: "black",
      2: "rgb(255,197,20)",
      3: "white",
    }),
  },
  ViewsIcon: {
    color: th({
      1: "black",
      2: "rgb(255,197,20)",
      3: "white",
    }),
  },

  Location: {
    height: th({
      1: "20.2%",
      2: "20.2%",
      4: "20.2%",
    }),
    svgWidth: th({
      3: "40%",
    }),
  },
  Whatsapp: {
    height: th({
      1: "36.2%",
    }),
  },
  Share: {
    height: th({
      1: "20.2%",
      2: "20.2%",
      4: "20.2%",
    }),
    svgWidth: th({
      3: "26%",
    }),
  },
  Views: {
    height: th({
      1: "20.2%",
      2: "20.2%",
      4: "20.2%",
    }),
    svgWidth: th({
      3: "40%",
    }),
  },

  QRCode: {
    position: th({
      3: "absolute",
      // 4: "absolute",
    }),
    bottom: th({
      3: "100px",
      // 4: "143px",
    }),
    right: th({
      3: "200%",
      // 4: "571px",
    }),
    color: th({
      3: "black",
    }),
    height: th({
      2: "39%",
    }),
    // width: th({
    //   4: "17%",
    // }),
    imgWidth: th({
      1: "39%",
      2: "39%",
      3: "66%",
      // 4: "128px",
    }),
  },
  EmptyAction: {
    // display: th({
    //   4: "none",
    // }),
  },
  SocialContainer: {
    position: th({
      3: "absolute",
    }),
    top: th({
      3: "-100px",
    }),
    width: th({
      1: "704px",
      2: "704px",
      3: "490px",
    }),
  },
  SocialLogo: {
    backgroundColor: th({
      1: "black",
      2: "rgb(255,197,20)",
      // 3: "rgb(10,51,113)",
    }),
    color: th({
      1: "white",
      2: "black",
      3: "black",
    }),
  },

  SlideContainer: {
    position: th({
      // 3: "absolute",
    }),
    top: th({
      3: "0px",
    }),

    margin: th({
      1: "20px auto",
      2: "20px auto",
      3: "20px auto",
    }),
    width: th({
      1: "704px",
      2: "704px",
      3: "80%",
    }),
  },
};
