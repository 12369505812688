import * as React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import styled from "styled-components";
import { Theme as t } from "./themes";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = ({ children, horizontal }) => {
  return (
    children.length > 0 && (
      <AutoplaySliderStyled
        horizontal={horizontal}
        onClick={() => console.log("click")}
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={6000}
        bullets={false}
        sliderHeightPercentage="100%"
      >
        {children}
      </AutoplaySliderStyled>
    )
  );
};
Slider.defaultPoops = {
  horizontal: true,
};

export default Slider;

const AutoplaySliderStyled = styled(AutoplaySlider)`
  height: ${({ horizontal }) => (horizontal ? "400px" : "960px;")};
  & img {
    object-fit: fill;
  }
  margin: ${t.SlideContainer.margin};

  position: ${t.SlideContainer.position};
  width: ${t.SlideContainer.width};
  bottom: ${t.SlideContainer.bottom};
`;
