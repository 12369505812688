import * as React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Theme as t } from "./themes";
import InputMask from "react-input-mask";

type State = {
  phone: string,
};
type Props = {
  showModal: boolean,
  onChange: () => void,
};
Modal.setAppElement("#root");

class PhoneDialog extends React.Component<Props, State> {
  state = { phone: "" };
  static defaultProps = {
    showModal: false,
  };
  handleInput = (e) => {
    this.setState({ phone: e.target.value });
  };
  render() {
    return (
      <Modal isOpen={this.props.showModal} style={ModalStyles}>
        <ModalContent>
          <Close
            onClick={() =>
              this.props.onChange({ target: { name: "close", value: "" } })
            }
          />
          <Message>
            Escribe el número de celular a 10 dígitos al que deseas compartir tu
            tarjeta
          </Message>
          <InputContainer>
            {/* +52{" "} */}
            <Phone
              type="text"
              mask="999-999-9999"
              maskChar=""
              onChange={this.handleInput}
              value={this.state.phone}
            />
          </InputContainer>
          <ButtonContainer>
            <Accept
              disabled={this.state.phone.length < 10}
              onClick={() =>
                this.props.onChange({
                  target: {
                    name: "send",
                    value: this.state.phone.replace(/-/g, ""),
                  },
                })
              }
            >
              Enviar
            </Accept>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    );
  }
}

export default PhoneDialog;

const ModalStyles = {
  content: {
    // width: "30%",
    maxWidth: "600px",
    padding: "0!important",
    // height: "10%",
    maxHeight: "300px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    boxShadow: "10px 10px 40px 0px",
  },
};

const Close = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        right: "10px",
        top: "11px",
        fontSize: "20px",
        cursor: "pointer",
      }}
    >
      <svg width="30px" height="30px">
        <text x="15" y="15" fill="currentColor">
          X
        </text>
      </svg>
    </div>
  );
};

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ModalContent = styled.div`
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 10px;
  background: ${(a) =>
    a.theme.customization.modal_background || t.Modal.background};
  /* background: ${t.Modal.background}; */
  color: ${(a) => a.theme.customization.modal_color || t.Modal.color};
  /* color: ${t.Modal.color}; */
  font-size: 24px;
  font-weight: 700;
`;

const Message = styled.div`
  margin: 40px 15px 0px 15px;
  text-align: center;
`;
const InputContainer = styled.div`
  margin: 10px 15px 0px 15px;
  text-align: center;
  font-size: 24px;
  color: ${t.Modal.color};
  font-weight: 700;
`;
const Phone = styled(InputMask)`
  margin: 10px 15px 0px 15px;
  text-align: center;
  font-size: 24px;
  color: ${(a) =>
    a.theme.customization.modal_input_color || t.Modal.phone.color};
  /* color: ${t.Modal.phone.color}; */
  font-weight: 700;
  width: 90%;
`;

const Accept = styled.button`
  // position: absolute;
  // bottom: 20px;
  // right: 20px;
  font-size: 24px;
  font-weight: 700;
  color: ${(a) =>
    a.theme.customization.modal_btn_color || t.Modal.accept.color};
  /* color: ${t.Modal.accept.color}; */
`;
