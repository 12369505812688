import Client from "../api/apolloclient";
import { gql } from "apollo-boost";

export const getProfileById = (profileId) => {
  return Client.query({
    query: gql`
      {
        profileuser (username:"${profileId}"){
          idprofile 
          logo
          image
          username
          name
          last_name
          horizontal
          company
          job
          phone
          cel
          address
          colony 
          zipcode
          email
          views
          logo_url
          avatar_url
          qr_url
          location
          message
          idtemplate
          facebook
          twitter
          instagram
          linkedin
          website
          city {
            description
            state {
              description
            }
          }
          slideshows {
            image_url
          }
          videos{
            external
            local_url
          }
        }
      }
    `,
  });
};
