import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 204 206" color="currentColor" {...props}>
      <g>
        <path
          d="M180.9,191.7C180.9,191.7,180.9,191.7,180.9,191.7c-4.9,4.9-16.3,12.4-37.2,6.9c-26.5-7-59.2-33.8-82.1-55.1
		l-0.1-0.1l-0.1-0.1C40.1,120.4,13.3,87.6,6.3,61.2c-5.5-20.9,2-32.3,6.9-37.2c1.4-1.4,2.6-2.2,3.1-2.6c1.1-0.8,7.7-5.7,13.4-9.7
		c7.7-5.4,17.4,0,21.9,4l0.2,0.2l16.7,16.7c12.8,12.8,4.6,24.2,1.1,29l-0.2,0.3c-5.7,6.9-1.6,14.1-1,15l0.3,0.4l0.1,0.3
		c3.7,8,19.5,26.3,25.5,33c6.7,6,25.1,21.8,33,25.5l0.4,0.2l0.3,0.2c0.9,0.6,8,4.7,15-1l0.3-0.2c4.9-3.5,16.2-11.7,29,1.1l17,17
		c4,4.5,9.3,14.1,4,21.8c-4,5.7-8.8,12.3-9.7,13.4C183.2,189.2,182.3,190.3,180.9,191.7z M69.5,135.4c30.9,28.7,58.3,47.3,77.1,52.3
		c15.4,4.1,23.1-0.7,26.3-4c0,0,0,0,0,0c0.7-0.7,1-1.2,1.1-1.3l0.2-0.3l0.1-0.1c0.1-0.1,5.4-7.3,9.6-13.3c1.1-1.6-1.4-5.9-3-7.7
		l-16.6-16.6c-5.1-5.1-8-4.5-14.2,0c-10.7,8.6-22.7,5.1-27.9,1.9c-11.2-5.4-33.2-25.1-35.7-27.4l-0.2-0.2l-0.2-0.2
		c-2.3-2.5-22-24.5-27.4-35.7c-3.2-5.2-6.8-17.2,1.9-27.9c4.5-6.2,5.1-9.2,0-14.2L44,24.1c-2.2-1.9-6.3-4-7.8-3
		c-6,4.1-13.2,9.5-13.3,9.6l-0.4,0.3c-0.2,0.1-0.7,0.5-1.3,1.1c-3.2,3.2-8,10.9-4,26.3C22.2,77.1,40.8,104.5,69.5,135.4z"
        />
        <g>
          <path
            d="M103.3,43.5c-3.1,0.4-5.3,3.2-5,6.3c0.4,3.1,3.2,5.3,6.3,5c0.2,0,19.5-2,33.3,11.8
			c13.9,13.9,12.5,34,12.5,34.2c-0.3,3.1,2.1,5.9,5.2,6.1c1.7,0.1,3.3-0.5,4.5-1.6c0.9-0.9,1.5-2.1,1.6-3.5
			c0.1-1,1.9-25.5-15.8-43.1C128.1,40.8,104.3,43.4,103.3,43.5z"
          />
          <path
            d="M99.3,8.8c-3.1,0.3-5.4,3.1-5,6.3c0.3,3.1,3.1,5.4,6.3,5c0.4,0,35.8-3.6,61.6,22.2
			c25.6,25.6,22.2,61.1,22.2,61.6c-0.3,3.1,1.9,5.9,5,6.3c1.8,0.2,3.5-0.5,4.6-1.6c0.9-0.9,1.5-2.1,1.6-3.4
			c0.2-1.7,4.2-41.2-25.4-70.9C140.5,4.6,101,8.7,99.3,8.8z"
          />
        </g>
      </g>
    </svg>
  );
};
