import React from "react";

export default (props) => {
  return (
    <svg viewBox="0 0 166 171" color="currentColor" {...props}>
      <g>
        <g>
          <path
            d="M119.9,118.6C119.9,118.6,119.9,118.6,119.9,118.6c-2.1,2.1-7,5.3-15.9,2.9c-11.3-3-25.2-14.4-35-23.5L69,98
			l-0.1-0.1c-9.1-9.7-20.5-23.7-23.5-35c-2.3-8.9,0.8-13.7,2.9-15.9c0.6-0.6,1.1-1,1.3-1.1c0.5-0.4,3.3-2.4,5.7-4.1
			c3.3-2.3,7.4,0,9.3,1.7l0.1,0.1l7.1,7.1c5.5,5.5,1.9,10.3,0.5,12.4l-0.1,0.1c-2.4,3-0.7,6-0.4,6.4l0.1,0.2l0.1,0.1
			C73.7,73.3,80.4,81.1,83,84c2.9,2.6,10.7,9.3,14.1,10.9l0.2,0.1l0.1,0.1c0.4,0.2,3.4,2,6.4-0.4l0.1-0.1c2.1-1.5,6.9-5,12.4,0.4
			l7.2,7.2c1.7,1.9,4,6,1.7,9.3c-1.7,2.4-3.8,5.2-4.1,5.7C120.8,117.5,120.5,118,119.9,118.6z"
          />
        </g>
        <path
          d="M5.1,161.9l11.5-43C0.9,89.6,6.2,53,30,29.3C44.4,14.9,63.5,6.9,83.9,6.9c20.4,0,39.5,7.9,53.9,22.3
		c29.7,29.7,29.7,78.1,0,107.8c-14.4,14.4-33.5,22.3-53.9,22.3c0,0,0,0,0,0c-12.5,0-24.9-3.1-35.8-8.9L5.1,161.9z M49.8,136.8
		l2.3,1.3c9.6,5.6,20.6,8.5,31.8,8.5c16.9,0,32.9-6.6,44.9-18.6c24.7-24.7,24.7-65,0-89.7c-12-12-27.9-18.6-44.9-18.6
		c-17,0-32.9,6.6-44.9,18.6C18.7,58.6,14.6,90.1,29,115l1.3,2.3l-7.1,26.7L49.8,136.8z"
        />
      </g>
    </svg>
  );
};
