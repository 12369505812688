//@flow
import * as React from "react";
import * as Styled from "./styled";
import * as Types from "./types";
import * as Icons from "../icons";
import PhoneDialog from "./phoneDialog";
import { contabilizeView } from "../api/misc";

type Props = {
  ...Types.Profile,
  vertical: boolean,
};

type State = {
  showModal: boolean,
};

class ECard extends React.Component<Props, State> {
  state = {
    showModal: false,
  };
  componentDidMount() {
    contabilizeView(this.props.username);
  }

  handleModal = (e) => {
    const { name, value } = e.target;
    const { name: first_name, last_name, username } = this.props;
    switch (name) {
      case "close":
        this.setState({ showModal: false });
        break;
      case "send":
        this.setState({ showModal: false });
        const message = encodeURI(
          `¡Hola soy *${first_name} ${last_name}*! Te comparto mi Entecard. https://entecard.mx/${username}`
        );
        window.open(`https://wa.me/52${value}?text=${message}`, "_blank");
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  render() {
    const {
      name,
      last_name,
      job,
      company,
      phone,
      cel,
      address,
      colony,
      zipcode,
      email,
      views,
      logo_url,
      avatar_url,
      qr_url,
      location,
      message,
      username,
      city,
    } = this.props;
    return (
      <div style={{ ...this.props.style }}>
        <Styled.CardContainer id="card-container">
          <Styled.Profile id="profile">
            <Styled.PictureContainer id="picture-container">
              <Styled.Picture
                id="picture"
                src={avatar_url}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "images/avatar.svg";
                }}
              />
              <Styled.LogoContainer id="logo-container">
                <Styled.Logo
                  id="logo"
                  src={logo_url}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "images/companylogo.jpg";
                  }}
                />
              </Styled.LogoContainer>
            </Styled.PictureContainer>
            <Styled.TextContainer id="text-container">
              <Styled.Company id="company">{company}</Styled.Company>
              <Styled.TextContainerDecoration id="decoration" />
              <Styled.Name id="name">{`${name} ${last_name}`}</Styled.Name>
              <Styled.Job id="job">{job}</Styled.Job>
            </Styled.TextContainer>
          </Styled.Profile>

          <Styled.ActionContainer>
            <Styled.QRCode>
              <img src={qr_url} alt="" />
              <Styled.IconText>ESCANÉAME</Styled.IconText>
            </Styled.QRCode>
            <Styled.ActionButton
              onClick={() =>
                window.open(
                  `https://api.v1.entecard.mx/add_contact?username=${username}`,
                  "_blank"
                )
              }
            >
              <Styled.Icon>
                <Icons.AddIcon fill="currentColor" />
              </Styled.Icon>
              <Styled.IconText>AGRÉGAME A TUS CONTACTOS</Styled.IconText>
            </Styled.ActionButton>
            <Styled.ActionButton
              onClick={() => window.open(`tel:${phone}`, "_blank")}
            >
              <Styled.Icon>
                <Icons.CallMeIcon fill="currentColor" />
              </Styled.Icon>
              <Styled.IconText>LLÁMAME</Styled.IconText>
            </Styled.ActionButton>
            <Styled.Empty style={{ height: 0 }}></Styled.Empty>
            <Styled.Whatsapp
              onClick={() =>
                window.open(`https://wa.me/52${cel}?text=${message}`, "_blank")
              }
            >
              <Styled.Icon>
                <Icons.WhatsappIcon fill="currentColor" />
              </Styled.Icon>
              <Styled.IconText>ENVÍAME UN WHATSAPP</Styled.IconText>
            </Styled.Whatsapp>
            <Styled.ActionButton
              onClick={() => window.open(`mailto:${email}`, "_blank")}
            >
              <Styled.Icon>
                <Icons.EmailIcon fill="currentColor" />
              </Styled.Icon>
              <Styled.IconText>ESCRÍBEME</Styled.IconText>
            </Styled.ActionButton>
            <Styled.Location
              hidden={!location}
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${address} ${colony} ${zipcode} ${city.description} ${city.state.description}`,
                  "_blank"
                )
              }
            >
              <Styled.LocationIcon fill="currentColor" />
              <Styled.IconText>MI UBICACIÓN</Styled.IconText>
            </Styled.Location>

            <Styled.Share onClick={() => this.setState({ showModal: true })}>
              <Styled.ShareIcon fill="currentColor" />
              <Styled.IconText>COMPARTIR MI WHATSAPP</Styled.IconText>
            </Styled.Share>
            <Styled.Views>
              <Styled.ViewsIcon fill="currentColor" />
              <Styled.IconText>{views} VISUALIZACIONES</Styled.IconText>
            </Styled.Views>
          </Styled.ActionContainer>
        </Styled.CardContainer>
        <PhoneDialog
          showModal={this.state.showModal}
          onChange={this.handleModal}
        />
      </div>
    );
  }
}

export default ECard;
